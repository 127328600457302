import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { Gift } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Props as StickerProps, Sticker } from 'app/components/Sticker'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import { useContext } from 'react'

import { Props as LanguageItemProps } from './LanguageItem'
import { Languages } from './Languages'
import { ModalClose } from './ModalClose'
import { Navigation } from './Navigation'
import { Syncrobox } from './Syncrobox'
import { Toolbars } from './Toolbars'

export interface Props {
  languagesList: LanguageItemProps[]
  menuItems: MenuItemProps[]
  pageID?: string
  stickerProps?: StickerProps
}

export const Header = memo(function Header({
  languagesList,
  menuItems,
  pageID,
  stickerProps,
}: Props) {
  const {
    IBEID,
    IBEURL,
    languageCode,
    languagePrefix,
    logo,
    logoSticky,
    quotationPageURL,
    siteName,
    voucherURL,
    variant,
  } = useContext(HeaderContext) as any

  const [_locked, setLocked] = useLockedBody()
  const [scroll, setScroll] = useState(false)
  const [navigationStatus, setNavigationStatus] = useState(false)

  const quotationPageSlug = quotationPageURL?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )?.slug

  const quotationRealPageURL = languagePrefix
    ? `/${languagePrefix}/${quotationPageSlug}`
    : `/${quotationPageSlug}`

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > 200)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {stickerProps ? <Sticker {...stickerProps} /> : null}

        <Head
          className={scroll || variant === 'compact' ? 'fixed' : undefined}
          dial={4}
          row
          variant={variant}
        >
          <Media lessThan="desktopSmall">
            <Hamburger
              className="hamburger-menu"
              dial={4}
              onClick={() => {
                if (!navigationStatus) {
                  setLocked(true)
                  setNavigationStatus(true)
                } else {
                  setLocked(false)
                  setNavigationStatus(false)
                }
              }}
              row
            >
              <HamburgerLines className="hamburger-lines">
                <HamburgerLine />
                <HamburgerLine />
                <HamburgerLine />
              </HamburgerLines>
              <HambugerLabel>
                {useVocabularyData('menu', languageCode)}
              </HambugerLabel>
            </Hamburger>
          </Media>

          {logo ? (
            <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
              {logoSticky ? (
                <Media lessThan="desktopSmall">
                  <Logo
                    aria-label={siteName}
                    className="logo"
                    src={logoSticky}
                    alt={siteName}
                    width="161"
                    height="30"
                  />
                </Media>
              ) : null}
              {logo ? (
                <Media greaterThanOrEqual="desktopSmall">
                  <Logo
                    aria-label={siteName}
                    className="logo"
                    src={scroll || variant === 'compact' ? logoSticky : logo}
                    alt={siteName}
                    width="214"
                    height="40"
                  />
                </Media>
              ) : null}
            </Link>
          ) : null}

          <Media
            className="navigation-desktop"
            greaterThanOrEqual="desktopSmall"
          >
            <Navigation
              isSticky={scroll || variant === 'compact'}
              menuItems={menuItems}
            />
          </Media>

          <Languages
            languagesList={languagesList}
            isSticky={scroll || variant === 'compact'}
            pageID={pageID}
          />

          <Media greaterThanOrEqual="desktopSmall">
            {voucherURL ? (
              <Voucher
                aria-label="Voucher"
                className="header-voucher"
                href={`${voucherURL}&lang=${languagePrefix || 'it'}`}
                rel="noopener"
                target="_blank"
              >
                <Gift />
              </Voucher>
            ) : null}
          </Media>

          <Media greaterThanOrEqual="desktopSmall">
            {(IBEURL && scroll) || variant === 'compact' ? (
              <Button
                className="header-book"
                icon="calendar"
                label={useVocabularyData('book', languageCode)}
                URL={`${IBEURL}&lang=${languagePrefix || 'it'}`}
                rel="noopener"
                target="_blank"
              />
            ) : null}
          </Media>

          <Media greaterThanOrEqual="desktopSmall">
            {quotationPageURL ? (
              <QuotationCTA
                label={useVocabularyData('ask-for', languageCode)}
                icon="mail"
                URL={quotationRealPageURL}
                variant="primary"
              />
            ) : null}
          </Media>
        </Head>

        {IBEID && variant === 'default' ? (
          <Syncrobox
            IBEID={IBEID}
            languageCode={languageCode}
            languagePrefix={languagePrefix || 'it'}
          />
        ) : null}

        <Media lessThan="desktopSmall">
          <NavigationWrap className={navigationStatus ? 'open' : ''}>
            <ModalClose
              languageCode={languageCode}
              onClick={() => {
                setLocked(false)
                setNavigationStatus(false)
              }}
            />
            <Navigation menuItems={menuItems} />
          </NavigationWrap>
        </Media>

        <Media lessThan="desktopSmall">
          <Toolbars />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .navigation-desktop {
    margin-right: auto;
    margin-left: 2.5rem;

    @media (max-width: 1199px) {
      margin-left: 1.875rem;
    }

    @media (max-width: 1023px) {
      margin: 0;
    }
  }
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  .logo {
    width: auto;
    height: 1.875rem;
  }
  .header-voucher {
    svg {
      stroke: ${theme.colors.variants.neutralDark4};
    }
  }
  .header-book {
    margin-right: 0.25rem;
  }

  @media (max-width: 1199px) {
    display: flex;
    justify-content: space-between;
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  @media (max-width: 340px) {
    .logo {
      height: 1.5rem;
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  padding: 2.9375rem 4.167vw 0;
  position: relative;
  &.fixed {
    animation: fadeInTop 0.3s;

    ${HeaderFixedStyle}
  }

  @media (max-width: 1339px) {
    padding: 2.9375rem 1.953vw 0;
  }

  @media (max-width: 1199px) {
    animation: none !important;

    ${HeaderFixedStyle}
  }

  @media (max-width: 1023px) {
    padding: 0 2rem;

    ${HeaderFixedStyle}
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`
const Hamburger = styled(FlexBox)`
  cursor: pointer;
  margin-right: auto;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    .hamburger-lines {
      div {
        &:nth-of-type(2) {
          width: 60%;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    margin-left: 0;
  }
`

const HamburgerLines = styled.div`
  width: 2.5rem;
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 0.125rem;
  background: ${({ theme }) => theme.colors.variants.secondaryLight};
  border-radius: 1.25rem;
  margin: 0.375rem 0;
  transition: 0.3s ease-in-out;
`

const HambugerLabel = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.125rem;
  margin-left: 1.3125rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Logo = styled.img``

const QuotationCTA = styled(Button)``

const Voucher = styled.a`
  display: block;
  border-right: 0.125rem solid ${rgba(theme.colors.variants.neutralLight4, 0.3)};
  margin: 0 1.875rem;

  svg {
    width: auto;
    height: 1.25rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
  }
`

const NavigationWrap = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  opacity: 0;
  overflow-y: auto;
  padding: 0 1.5rem 7.5rem 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
  transition: 0.3s ease-out;
  -webkit-overflow-scrolling: auto;
  border-top: 1px solid ${theme.colors.variants.neutralLight2};

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 51;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'offer'
